.rootContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1400px;
}

.root {
  width: 100vw;
  height: 100vh;
}

.LoadingPage {
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("../assets/loadingImage.png");
}

.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerUI {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  padding-top: 10px;
  gap: 10px;
}

.containerPreviewImage {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.PreviewImage {
  width: 50%;
  height: 40%;
  margin-bottom: 2%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.5;
}

.control {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.MainMenuContainer {
  width: 100%;
  height: 130px;
  border-radius: 25px 25px 0px 00px;
  z-index: 2;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.42);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 15px;
  margin-top: auto;
}

.MainMenuContainerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.MainMenuControlIdle {
  width: 100%;
  height: 90px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
}

.MainMenuControlActive {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  position: relative;
}

.MainMenuControlTitle {
  display: flex;
  font-size: 25px;
  height: 100%;
  width: 65%;
  font-weight: bold;
  margin-left: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.MainMenuControlUnMuteButton,
.MainMenuControlMuteButton,
.MainMenuControlPauseButton,
.MainMenuControlPlayButton {
  display: flex;
  font-size: 25px;
  font-weight: bold;
  min-width: 40px;
  min-height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/livePhotoPlay.png");
}

.MainMenuControlPauseButton {
  background-image: url("../assets/livePhotoPause.png");
}

.MainMenuControlUnMuteButton,
.MainMenuControlMuteButton {
  position: fixed;
  right: 25px;
  min-width: 25px;
  min-height: 25px;
  bottom: 25px;
  background-image: url("../assets/volumeDown.png");
}

.MainMenuControlUnMuteButton {
  background-image: url("../assets/volumeUp.png");
}

.OpenAppButtonAppButtonContainer,
.DownloadAppButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  backdrop-filter: blur(30px);
  padding: 10px;
  background-color: #00000054;
}

.OpenAppButtonAppButtonContainer {
  max-width: 60px;
}

.OpenAppButtonAppButtonContainer span,
.DownloadAppButtonContainer span {
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 7px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 5px;
  gap: 5px;
  font-size: 12px;
  font-weight: bold;
}

.DownloadAppButton {
  width: 90px;
  height: 30px;
  cursor: pointer !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: bold;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/applebutton.svg");
}

.MainMenuButtons {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
}

.MainMenuButtonsContianer {
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.MainMenuButton {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.MainMenuButtonIcon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: red;
}

.MainMenuButtonRevivarIcon {
  background-image: url("../assets/LOGO_white.png");
}

.MainMenuButtonLocationIcon {
  background-image: url("../assets/location.fill.viewfinder@2x.png");
}

.MainMenuButtonShop {
  background-image: url("../assets/shopIcon.png");
}

.MainMenuButtonMap {
  background-image: url("../assets/location.fill.viewfinder@2x.png");
}

.CloseMenuButtonMap {
  background-image: url("../assets/ic_round-close.png");
}

.PreviewMapContainer {
  width: calc(100vw - 160px);
  height: calc(100vh - 160px);
  overflow: hidden;
  position: fixed;
  top: 80px;
  /* Convert to dark mode */
  filter: grayscale(1) invert(1);
  border-radius: 30px;
}

.UserPositionMarker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PostersMarker {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../assets/logoBlack.png");
  background-size: contain;
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
}

.MainMenuButtonTextIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
}

.LoadingView {
  width: 90%;
  height: 30%;
  background-color: rgba(0, 0, 0, 0.359);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid white;
  flex-direction: column;
  backdrop-filter: blur(5px);
  margin-top: 20%;
}

.LoadingViewLogo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 30%;
  height: 30%;
  background-image: url("../assets/LOGO_white.png");
}

.LoadingViewProgressBarContainer {
  margin-top: 20px;
  width: 90%;
  height: 10px;
  border-radius: 20px;
  /* background-color: white; */
  border: 1px solid white;
}

.LoadingViewProgressBar {
  width: 90%;
  height: 100%;
  border-radius: 20px;
  background-color: white;
}

.LoadingViewProgressInfo {
  width: 90%;
  margin-top: 20px;
  border-radius: 20px;
  /* background-color: red; */
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  appearance: none;
  -webkit-appearance: none;
}

.TopAppButtonContainer {
  width: 100vw;
  display: flex;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.AppButton {
  height: 60px;
  width: 60px;
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

.AppButton .OpenAppButtonAppButtonContainer {
  width: 100%;
}

.LoadingPreview {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
